/*************************
*******Header******
**************************/

#header .search{
  display: inline-block;
}

.navbar>.container .navbar-brand{
  margin-left: 0;
}

.top-bar {
  padding: 10px 0;
  background: #fff;
  border-bottom: 1px solid #222;
  line-height: 28px;
}

.top-number{
  color: #fff;
}

.top-number p{
  margin: 0
}

.social{
  text-align: right;
}

.social-share{
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.social-share li {
  display: inline-block;
}

ul.social-share li a {
  display: inline-block;
  color: #fff;
  background: #ED1C24;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

ul.social-share li a:hover {
  background: #c52d2f;
  color: #fff;
}

.search i {
  color: #ED1C24;
}

input.search-form{
  background: transparent;
  border: 0 none;
  width: 60px;
  padding: 0 10px;
  color: #000;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -ms-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

input.search-form::-webkit-input-placeholder{
  color: transparent;
}

input.search-form:hover::-webkit-input-placeholder {
  color: #fff;
}

input.search-form:hover {
  width: 180px;
}

.navbar-brand {
  padding: 0;
  margin:0;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  background: #ED1C24;
  padding: 15px 0;
  padding-bottom: 0;
}

.navbar-nav{
  margin-top: 12px;
}

.navbar-nav>li{
  margin-left: 35px;
  padding-bottom: 28px;
  font-size: 1.1em;
  font-weight: 300;
}

.navbar-inverse .navbar-nav > li > a {
  padding: 5px 12px;
  margin: 0;
  border-radius: 0;
  color: #fff;
  line-height: 24px;
  display: inline-block;
}

.navbar-inverse .navbar-nav > li > a:hover{
  background-color: #ebcd00;
  color: #000;
}

.navbar-inverse {
  border: none;
}

.navbar-inverse .navbar-brand {
  font-size: 36px;
  line-height: 50px;
  color: #fff;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #ebcd00;
  color: #000;
}

.navbar-inverse .navbar-nav .dropdown-menu {
  background-color: rgba(0,0,0,.85);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  border: 0;
  padding: 0;
  margin-top: 0;
  border-top: 0;
  border-radius: 0;
  left: 0;
}

.navbar-inverse .navbar-nav .dropdown-menu:before{
  position: absolute;
  top:0;
}

.navbar-inverse .navbar-nav .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #fff;
}

.navbar-inverse .navbar-nav .dropdown-menu > li:hover > a,
.navbar-inverse .navbar-nav .dropdown-menu > li:focus > a,
.navbar-inverse .navbar-nav .dropdown-menu > li.active > a {
  background-color: #ebcd00;
  color: #fff;
}

.navbar-inverse .navbar-nav .dropdown-menu > li:last-child > a {
  border-radius: 0 0 3px 3px;
}

.navbar-inverse .navbar-nav .dropdown-menu > li.divider {
  background-color: transparent;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-top: 0;
  padding-bottom: 0;
}