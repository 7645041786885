@import "bootstrap";
//@import "bootstrap/theme";
@import "header";
@import "jumbotron";
@import "carousel-fade";

.btn-primary {
  background-color: $red;
  border: $red;
  transition: all 0.3s ease-in-out 0s;
}

.btn-primary:hover {
  background-color: $yellow;
  border: $yellow;
  color: #000;
  transition: all 0.3s ease-in-out 0s;
}

.btn-default {
  background-color: #fff;
  border: 1px solid $blue;
  transition: all 0.3s ease-in-out 0s;
}

.btn-default:hover {
  background-color: $blue;
  border: 1px solid $blue;
  color: #000;
  transition: all 0.3s ease-in-out 0s;
}

.white {
  background-color: #fff;
}

.column-content {
  p, h1, h2, h3, h4 {
    padding: 10px;
  }

  h1, h2, h3, h4 {
    font-weight: 300;
    color: $red;
  }
}



.site-footer {
  background-color: $red;
  color: #fff;
  padding: 30px 0;

  h1, h2, h3, h4 {
    font-weight: 300;
    font-size: 1.8em;
  }

  p {
    font-weight: 100;
    line-height: 1.6em;
  }
}

.bottom-footer {
  padding: 15px 0;
}

.partners {
  color: #fff;
  padding: 30px 0;

  h1, h2, h3, h4 {
    font-weight: 300;
    font-size: 1.8em;
  }

}

.article {
  border-top: 8px solid $blue;
  border-bottom: 1px solid $blue;
  padding: 48px 30px;

  h1 {
    padding-bottom: 0;
  }

  h2 {
    font-size: 1.4em;
    font-weight: 700;
    padding-top: 0;
    margin-bottom: 0px;
  }

  .footer-info {
    color: #aaa;
  }
}

.column-menu {
  padding: 48px 10px;
  border-top: 8px solid $gray-light;
  border-bottom: 1px solid $gray-light;
}

.content-button {
  position: absolute; bottom: 15px; right: 35px;
}

.frontpage-partner {
  -webkit-clip-path: polygon(14% 0, 100% 0, 100% 100%, 0 100%, 0 23%);
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0 100%, 0 23%);
  width: 100%;
  height: 100px;
  background-position: 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: #fff;
}

.partnerpage-partner {
  border-bottom: 2px solid #b9cdea;
  width: 100%;
  height: 100px;
  padding-top: 10px;
}

.logo-wrapper {
  width: 90%;
  height: 80px;
  background-position: 50%;
  background-size:contain;
  background-repeat: no-repeat;
  margin: auto;

}

.agenda {
  margin-bottom: 15px;

  p {
    margin: 20px 15px 3px 15px;
    padding: 1px;
  }

  h3 {
    margin: 6px 15px;
    padding: 0;
  }

  .datum {
    color: $gray-light;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tv {

  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 180px;

  overflow: hidden;

  .screen {

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    margin: auto;
    opacity: 0;
    transition: opacity .5s;

    &.active {
      opacity: 1;
    }
  }
}