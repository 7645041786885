.carousel {
  color: #000;
  height: 400px;


  h1 {
    font-size: 1.8em;
    color: $red;
    font-weight: 300;
  }

  p {
    font-size: 1.3em;
  }

  .btn {
    text-shadow: none;
  }

  .carousel-caption2 {
    position: absolute;
    background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
    width: 40%;
    padding: 15px;
    text-shadow: none;
    text-align: left;
    font-weight: 300;
    color: #000;
    top: 50px;
  }
}